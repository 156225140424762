import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import Header from '../../components/header/header';
import ButtonBody from '../../components/button/button';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
import { lang } from '../langs';
import TableList from './table-list';
import { Level3ServicesPagePath } from './constants';
import { ReactNode, useEffect, useState } from 'react';
interface Props {
    children?: ReactNode;
    updates?: boolean;
}

const AppComponentsPage = ({ children, updates }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pagePernissionsSettingsAppFunction: { title, buttonNew, tableTitle }
            // detailsTitle
        }
    } = lang;
    const { id: appSectionId, appComponentId } = useParams();
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        if (updates) {
            setReload(updates);
            setTimeout(() => {
                setReload(false);
            }, 50);
        }
    }, [updates]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Configuración de Permisos'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: title }} icon='GestorsIconB.svg' />
                <div
                    className='settings__grid-container'
                    id='AppfuPage/FunctionsList'
                >
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {tableTitle}
                        </div>
                        <TableList refresh={reload} />
                        <div
                            className='settings__grid-container__item__content'
                            id='AppfuPage/FunctionsList/New'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={buttonNew}
                                path={`/panel/${Level3ServicesPagePath}/${appSectionId}/app-component/${appComponentId}/app-function/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default AppComponentsPage;
