/* eslint-disable @typescript-eslint/no-explicit-any */
import cookie from 'react-cookies';
import moment from 'moment';
import CryptoJS from 'crypto-js';

const appSecret = 'testplaning-secret';
const expires = moment().add(1, 'd').toDate();

const encodeData = (data: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), appSecret).toString();
};

const decodeData = (data: any) => {
    const bytes = CryptoJS.AES.decrypt(data, appSecret);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const SetCookie = (data: any, name: string, remember = false) => {
    const timeout: any = remember ? moment().add(1, 'y').toDate() : expires;
    cookie.save(name, encodeData(data), { path: '/', maxAge: timeout });
};

export const ReadCookie = (name: string) => {
    if (cookie.load(name) !== undefined) {
        const data = decodeData(cookie.load(name));
        return data;
    } else {
        return false;
    }
};

export const RemoveCookie = (name: string | any) => {
    cookie.remove(name, { path: '/' });
};
