import '../calendar.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface ITodo {
    _id: string;
    title: string;
    color?: string;
    disabled: boolean;
}

interface Props {
    label: string;
    onNavigate: (action: 'PREV' | 'NEXT') => void;
    onView: (view: 'month' | 'week' | 'day') => void;
    todos: ITodo[];
    view: 'month' | 'week' | 'day'; // Recibe la vista actual
    setView: (view: 'month' | 'week' | 'day') => void; // Recibe la función para cambiar la vista
}

const CalendarHeader = ({
    label,
    onNavigate,
    onView,
    todos,
    view,
    setView
}: Props) => {
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleViewChange = (newView: 'month' | 'week' | 'day') => {
        setView(newView);
        onView(newView);
    };

    return (
        <div className='calendar-header'>
            <div className='calendar-header__navigate title'>
                <button
                    className='calendar-header__navigate__btn'
                    onClick={() => onNavigate('PREV')}
                >
                    <LeftOutlined />
                </button>
                <span className='calendar-header__navigate__month'>
                    {capitalizeFirstLetter(label)}
                </span>
                <button
                    className='calendar-header__navigate__btn'
                    onClick={() => onNavigate('NEXT')}
                >
                    <RightOutlined />
                </button>
            </div>
            <div className='calendar-header__categories subtitle'>
                {todos.map(todo => (
                    <div
                        key={todo._id}
                        className={`calendar-header__categories__item ${
                            todo.disabled && 'disabled'
                        }`}
                        style={{ backgroundColor: todo.color }}
                    >
                        {todo.title}
                    </div>
                ))}
            </div>
            <div className='calendar-header__view subtitle'>
                <button
                    className={`calendar-header__view__item ${
                        view === 'month' ? 'view-item-active' : ''
                    }`}
                    onClick={() => handleViewChange('month')}
                >
                    Mes
                </button>
                <button
                    className={`calendar-header__view__item ${
                        view === 'week' ? 'view-item-active' : ''
                    }`}
                    onClick={() => handleViewChange('week')}
                >
                    Semana
                </button>
                <button
                    className={`calendar-header__view__item ${
                        view === 'day' ? 'view-item-active' : ''
                    }`}
                    onClick={() => handleViewChange('day')}
                >
                    Día
                </button>
            </div>
        </div>
    );
};

export default CalendarHeader;
