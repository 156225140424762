import { useEffect, useState } from 'react';
import './qr-item.scss';
import { useNavigate, useParams } from 'react-router';
//  <--COMPONENTS--> //
import FileViewer from 'react-file-viewer';
import PdfViewer from '../../../components/pdfViewer/pdfViewer';
//  <--HOOKS--> //
import { ApiQrLinDetail, ApiQrLinkByIdentity } from '../../../config/service';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { useAppSelector } from '../../../store/hooks';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';

const QRItem = () => {
    const { GetData } = useCallApi();
    const { identity } = useParams();
    const navigate = useNavigate();
    const [qrData, setQrData] = useState<{
        fileType?: string;
        destination?: string;
    }>({});

    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );

    useEffect(() => {
        const showFile = async data => {
            data.fileType = data.destination.split('.').pop();
            setQrData(data);
        };

        const redirectExternal = (data: { destination: string }) => {
            if (data.destination === 'https://') {
                return null;
            } else if (data.destination.startsWith('https://')) {
                window.location.href = data.destination;
            } else {
                window.location.href = 'https://' + data.destination;
            }
            return null;
        };

        const redirectInternal = data => {
            navigate(data.destination);
        };

        const obtainAndSendUserData = async () => {
            const userGeolocation = await obtainGeolocation();

            const data = {
                latitud: userGeolocation.latitud,
                longitud: userGeolocation.longitud,
                email: userData.email,
                qrIdentity: identity,
                additionalData: JSON.stringify({
                    userData,
                    navigator: navigator.userAgent
                })
            };

            try {
                await GetData(ApiQrLinDetail, HTTP_METHODS.POST, data);
            } catch {}
        };

        interface Geolocation {
            latitud: number;
            longitud: number;
        }

        const obtainGeolocation = async (): Promise<Geolocation> => {
            return await new Promise<Geolocation>((resolve, reject) => {
                const geoSuccess = position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve({
                        latitud: latitude,
                        longitud: longitude
                    });
                };

                const geoError = error => {
                    console.log('Geolocation error:', error);
                    reject(new Error('Unable to retrieve location'));
                };

                const geoOptions = {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                };

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        geoSuccess,
                        geoError,
                        geoOptions
                    );
                } else {
                    reject(
                        new Error('Geolocation not supported by this browser')
                    );
                }
            });
        };

        const CallMainData = async () => {
            if (identity !== undefined) {
                const { data, status } = await GetData(
                    ApiQrLinkByIdentity(identity),
                    HTTP_METHODS.GET
                );

                obtainAndSendUserData();

                if (status) {
                    if (data.type === 'external-url') {
                        redirectExternal(data);
                    } else if (data.type === 'internal-url') {
                        redirectInternal(data);
                    } else if (data.type) {
                        await showFile(data);
                    }
                } else {
                    navigate('/404');
                }
            }
        };

        CallMainData();
    }, []);

    return (
        <div className='qr-item'>
            <div className='qr-item__content'>
                {qrData?.fileType &&
                    (qrData.fileType === 'pdf' ? (
                        <PdfViewer file={qrData.destination} />
                    ) : (
                        <div className='qr-item__content__data'>
                            <FileViewer
                                fileType={qrData.fileType}
                                filePath={qrData.destination}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default QRItem;
