import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import Header from '../../components/header/header';
import ButtonBody from '../../components/button/button';
//  <--HOOKS--> //
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
import { lang } from '../langs';
import TableList from './table-list';
import { Level2ServicesPagePath } from './constants';
import { ReactNode, useEffect, useState } from 'react';
import { GetStorage } from '../../config/utils/Storage';
interface Props {
    children?: ReactNode;
    updates?: boolean;
}
const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const AppComponentsPage = ({ children, updates }: Props) => {
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pagePernissionsSettingsAppComponent: {
                title,
                buttonNew,
                tableTitle
            }
            // detailsTitle
        }
    } = lang;
    const { id } = useParams();
    const [reload, setReload] = useState<boolean>(false);
    const [selectedSection, setSelectedSection] = useState<string>('');

    useEffect(() => {
        if (appSections) {
            const select = appSections.find(item => item.id === id);
            if (select) {
                setSelectedSection(select?.name);
            }
        }
    }, [appSections]);

    useEffect(() => {
        if (updates) {
            setReload(updates);
            setTimeout(() => {
                setReload(false);
            }, 50);
        }
    }, [updates]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Configuración de Permisos'
            ></SubNavBar>
            <div className='settings'>
                <Header title={{ name: title }} icon='GestorsIconB.svg' />
                <div className='settings__grid-container'>
                    <div
                        className='settings__grid-container__item'
                        id='AppcoPage/ComponentList'
                    >
                        <div className='settings__grid-container__item__title title'>
                            {`${tableTitle} de ${selectedSection}`}
                        </div>
                        <TableList refresh={reload} />
                        <div
                            className='settings__grid-container__item__content'
                            id='AppcoPage/ComponentList/New'
                        >
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={buttonNew}
                                path={
                                    id
                                        ? `/panel/${Level2ServicesPagePath}/${id}/app-component/create`
                                        : `/panel/${Level2ServicesPagePath}/app-component/create`
                                }
                            ></ButtonBody>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default AppComponentsPage;
