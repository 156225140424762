import { useEffect, useState } from 'react';
import DetailsComponent from '../../details/details';
import LeftSection from './leftsection/leftsection';
import RightSection from './rightsection/rightsection';
import BodySection from './body/body';
import { useAppSelector } from '../../../store/hooks';
import { useToast } from '../../../config/hooks/useToast';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import { useToastHook } from '../../../store/slices/toast/toast.hook';
import './main-private-style.scss';
import IdleTimer from '../../idle/idle-timer';
import { appCookie, Logout } from '../../../config/utils/Auth';
import { RemoveCookie } from '../../../config/utils/Cookie';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { lang } from './langs';
import CreateCalendar from '../../../pages/settings/calendar/components/create/create-calendar';
import { GetStorage } from '../../../config/utils/Storage';
import { useLocation } from 'react-router-dom';
import { UseConfigurePermissions } from './ConfigurePermissions';
import { useNavigate } from 'react-router';
const Main = ({ children }: any) => {
    const { AssignPermissions, PermissionsLoader } = UseConfigurePermissions();
    const location = useLocation();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { ToastConfig } = useToastHook();
    const { show, toastData } = useAppSelector(state => state.toast);
    const { preferences, userData, defaultProfile }: UserStoreInterface =
        useAppSelector(state => state.users);
    const { roles } = userData;
    const selectedRole = roles[defaultProfile]?.name;
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: { inactivityMessage }
    } = lang;
    const [openButtons, setOpenButtons] = useState<boolean>(false);
    const [leftSectionOpen, setLeftSectionOpen] = useState<boolean>(false);
    const [createCalendar, setCreateCalendar] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [withLeftSection, setWithLeftSection] = useState<boolean>(false);
    const navigate = useNavigate();

    const OpenButtons = () => {
        setOpenButtons(!openButtons);
    };

    const handleCloseCalendar = () => {
        setCreateCalendar(false);
    };

    useEffect(() => {
        if (show) {
            Alerts(toastData.message, toastData.color);
        }
    }, [show]);

    const CallPermissions = () => {
        const userData = GetStorage(
            process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE
        );
        const clientData = userData.clientData;
        AssignPermissions(clientData);
    };

    useEffect(() => {
        CallPermissions();
    }, [location]);

    const Alerts = (message, color) => {
        ConfigToast.text = message;
        ConfigToast.backColor = color;
        setConfigToast(ConfigToast);
    };

    const OnLogout = async () => {
        RemoveCookie(appCookie);
        RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
        setTimeout(() => {
            Logout(navigate);
        }, 100);
    };

    const LogoutIddleTimer = () => {
        ToastConfig({
            message: inactivityMessage,
            color: Colors.Warning
        });
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        CallPermissions();
    }, [isMobile]);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (
            !isMobile &&
            (currentPath.includes('data-center') ||
                currentPath.includes('evolution') ||
                currentPath.includes('ranking') ||
                currentPath.includes('garbage-control') ||
                currentPath.includes('control-panel') ||
                currentPath.includes('registers'))
        ) {
            setLeftSectionOpen(true);
            setWithLeftSection(true);
        } else {
            setLeftSectionOpen(false);
            setWithLeftSection(false);
        }
    }, [window.location.pathname]);

    return (
        <IdleTimer handler={OnLogout} onMessage={LogoutIddleTimer}>
            <main>
                <PermissionsLoader />
                <ToastElement ref={toastManagerRef} />
                <div className='pryma-container'>
                    {isMobile && (
                        <div
                            className={`pryma-container__left-section${
                                leftSectionOpen ? '__open' : ''
                            }`}
                        >
                            <DetailsComponent
                                showSection={isMobile && leftSectionOpen}
                                place='left'
                            >
                                <LeftSection
                                    onClose={() =>
                                        setLeftSectionOpen(!leftSectionOpen)
                                    }
                                />
                            </DetailsComponent>
                        </div>
                    )}
                    {!isMobile && leftSectionOpen ? (
                        <div className='pryma-container__left-section'>
                            <LeftSection />
                            {!withLeftSection && (
                                <button
                                    onClick={() =>
                                        setLeftSectionOpen(!leftSectionOpen)
                                    }
                                    className='pryma-container__left-section__button'
                                >
                                    <div className='pryma-container__left-section__button__lines'></div>
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className='pryma-container__reduce-left-section'>
                            <button
                                onClick={() =>
                                    setLeftSectionOpen(!leftSectionOpen)
                                }
                                className='pryma-container__reduce-left-section__button'
                            >
                                <div className='pryma-container__reduce-left-section__button__lines'></div>
                            </button>
                            <div className='pryma-container__reduce-left-section__logo'>
                                <img src={`/svg-icons/PrymaMini.svg`} />
                            </div>
                        </div>
                    )}
                    <div className='pryma-container__main-content'>
                        <BodySection>{children}</BodySection>
                    </div>
                    <div className='pryma-container__right-section'>
                        <RightSection />
                    </div>
                </div>
                {isMobile && (
                    <div className='options_button__item options_button__item__main-left left-section'>
                        <button
                            className={
                                openButtons
                                    ? 'options_button__item__button __button-left open'
                                    : 'options_button__item__button __button-left closed'
                            }
                            onClick={() => setLeftSectionOpen(!leftSectionOpen)}
                        >
                            <img
                                className='options_button__item__button__img'
                                src={`/svg-icons/settings/PropertyIconW.svg`}
                            />
                        </button>
                    </div>
                )}
                <div
                    className='options_button'
                    id='GlobalComponent/FloatButton'
                >
                    {openButtons && (
                        <>
                            <div
                                className='options_button__item options_button__item__top'
                                id='GlobalComponent/FloatButton/AddEvent'
                            >
                                <button
                                    className='options_button__item__button __button subsubtitle'
                                    onClick={() => setCreateCalendar(true)}
                                >
                                    {selectedRole.includes('BZero') ||
                                    selectedRole.includes('Gestor') ? (
                                        <>
                                            Crear <br /> evento
                                        </>
                                    ) : (
                                        <>
                                            Solicitar <br /> retiro
                                        </>
                                    )}
                                </button>
                            </div>
                            <div
                                className='options_button__item options_button__item__left'
                                id='GlobalComponent/FloatButton/InputData'
                            >
                                <button
                                    className='options_button__item__button __button subsubtitle disabled'
                                    disabled
                                >
                                    Input <br /> Data
                                </button>
                            </div>
                        </>
                    )}
                    <div className='options_button__item options_button__item__main'>
                        <button
                            className={
                                openButtons
                                    ? 'options_button__item__button __button open bigtitle'
                                    : 'options_button__item__button __button closed bigtitle'
                            }
                            style={{ color: '#fff' }}
                            onClick={OpenButtons}
                        >
                            {openButtons ? '-' : '+'}
                        </button>
                    </div>
                </div>
                {createCalendar && (
                    <CreateCalendar onClose={handleCloseCalendar} />
                )}
            </main>
        </IdleTimer>
    );
};

export default Main;
