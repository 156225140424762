//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import { DatePicker, ConfigProvider, Checkbox, Tooltip, Popover } from 'antd';
import dayjs from 'dayjs';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import useWindowDimensions from '../../../config/hooks/useWindowDimentions';
import './garbage-control.scss';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { initValues } from '../data';
import { StackedBarChart } from '../../../components/charts';
import { lang } from '../../langs';
import { NormalizeName, Zfill } from '../../../config/utils/Utilities';
import ChartFormated from '../../../components/dataCenterComponents/garbageControl/components/chartFormated';
import GarbageCostsTable from './components/garbage-costs-table';
import {
    ApiDownloadGarbageProratio,
    ApiPropertyDataByPropertyGestor,
    ApiPropertyDataByPropertyUnit
} from '../../../config/service';
/* import { SupplyInterface } from '../../../interface'; */
import { FormatLabels } from '../../../config/utils/IdbFormatData';
import { MoreOutlined } from '@ant-design/icons';

const Stacked1 = StackedBarChart;
Stacked1.Config.plugins.legend.display = false;
Stacked1.Config.plugins.legend.position = 'top';
Stacked1.Config.plugins.title.text = '';

const { RangePicker } = DatePicker;

interface BagItem {
    name: string;
    capacity: number;
    selected: boolean;
    show: boolean;
}

const GarbageControl = () => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                months,
                pageGarbageControl: { title, dateRangeTitle }
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );

    const [labels, setLabels] = useState<string[]>([]);
    const [dateFilterRange, setDateFilterRange] = useState<string[]>([]);
    const [labelList, setLabelList] = useState<string[]>([]);
    const [defaultStartDate, setDefaultStartDate] = useState<any>();
    const [defaultEndDate, setDefaultEndDate] = useState<any>();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [garbageData, setGarbageData] = useState<any>(initValues);
    const [downloadGarbageCost, setDownloadGarbageCost] = useState<string>('');
    const [downloadDate, setDownloadDate] = useState<string>('yyyymm');
    const [showCosts, setShowCosts] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [bags, setBags] = useState<BagItem[]>([
        {
            name: 'Bolsa 30 l',
            capacity: 30,
            selected: true,
            show: true
        },
        {
            name: 'Bolsa 80 l',
            capacity: 80,
            selected: true,
            show: true
        },
        {
            name: 'Bolsa 140 l',
            capacity: 140,
            selected: true,
            show: true
        },
        {
            name: 'Bolsa 220 l',
            capacity: 220,
            selected: true,
            show: true
        }
    ]);
    const [removedDatasets, setRemovedDatasets] = useState<any[]>([]);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6); // label es 'yyyymm', así que solo extraemos los últimos 2 caracteres
            return `${months[parseInt(month) - 1].slice(0, 1)}`; // Obtén la primera letra del mes
        });
        setFormattedLabels(formatted);
    };

    const ChangeDate = date => {
        const ini = `${date[0].$y}${Zfill(parseInt(date[0].$M) + 1, 2)}`;
        const fin = `${date[1].$y}${Zfill(parseInt(date[1].$M) + 1, 2)}`;
        // eslint-disable-next-line array-callback-return
        setDateFilterRange([ini, fin]);
    };

    const GetParams = async (garbageCost, date, showCosts) => {
        setDownloadGarbageCost(garbageCost);
        setDownloadDate(date);
        setShowCosts(showCosts);
    };

    const DownloadGarbageProratios = async () => {
        const values = {
            date: `${downloadDate.slice(0, 4)}-${downloadDate.slice(4)}`,
            garbageCost: downloadGarbageCost,
            propertyUnits: selectedUnits.map(item => item.id)
        };
        const response = await GetData(
            ApiDownloadGarbageProratio,
            HTTP_METHODS.POST,
            values
        );
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    const updateAllSelectedData = async () => {
        const uniqueSelected = selected.filter(
            (property, index, self) =>
                index === self.findIndex(p => p.id === property.id)
        );
        const propertyDataList = await Promise.all(
            uniqueSelected.map(async property => {
                const propertyDataStr = localStorage.getItem(
                    `property-${property.id}`
                );
                let propertyData;

                if (propertyDataStr) {
                    propertyData = JSON.parse(propertyDataStr);
                } else {
                    let gestor: string | null = null;
                    for (const role of userData.roles) {
                        if (role.gestorData) {
                            gestor = role.gestorData.id;
                            break;
                        }
                    }
                    propertyData = await GetData(
                        ApiPropertyDataByPropertyGestor,
                        HTTP_METHODS.POST,
                        { property: property.id, gestor }
                    );
                    localStorage.setItem(
                        `property-${property.id}`,
                        JSON.stringify(propertyData)
                    );
                }

                return propertyData;
            })
        );
        const uniqueSelectedUnits = selectedUnits.filter(
            (property, index, self) =>
                index === self.findIndex(p => p.id === property.id)
        );
        // Obtener datos de las unidades seleccionadas
        const propertyUnitDataList = await Promise.all(
            uniqueSelectedUnits.map(async unit => {
                const unitDataStr = localStorage.getItem(
                    `property-unit-${unit.id}`
                );
                let unitData;

                if (unitDataStr) {
                    unitData = JSON.parse(unitDataStr);
                } else {
                    unitData = await GetData(
                        ApiPropertyDataByPropertyUnit(unit.id),
                        HTTP_METHODS.GET
                    );
                    localStorage.setItem(
                        `property-unit-${unit.id}`,
                        JSON.stringify(unitData)
                    );
                }

                return unitData;
            })
        );

        // Combinar datos de propiedades y unidades en una sola lista
        const allDataList = [...propertyDataList, ...propertyUnitDataList];
        // Acumulación de datos para actualizar los estados
        let accumulatedData: any = null;
        // Procesar datos combinados
        allDataList.forEach(data => {
            const garbagecontrolCard = data.data.find(
                item => item.kind === 'garbagecontrol-card'
            );
            if (garbagecontrolCard?.data /* && bzeroindexCard?.data */) {
                const newData = JSON.parse(garbagecontrolCard.data);

                if (!accumulatedData) {
                    accumulatedData = newData;
                } else {
                    // Crear un nuevo conjunto que combine ambos datasets, sin perder datos
                    const combinedDatasets: any = [];

                    // Procesar los datasets que están en accumulatedData
                    accumulatedData.datasets.forEach(accumulatedDataset => {
                        const correspondingNewDataset = newData.datasets.find(
                            ds => ds.id === accumulatedDataset.id
                        );

                        if (correspondingNewDataset) {
                            // Si existe en ambos, acumular los datos
                            const updatedDataset: any = {
                                ...accumulatedDataset,
                                data: accumulatedDataset.data.map(
                                    (value, index) => {
                                        const currentValue =
                                            Math.round(parseFloat(value)) || 0;
                                        const newValue =
                                            Math.round(
                                                parseFloat(
                                                    correspondingNewDataset
                                                        .data[index]
                                                )
                                            ) || 0;
                                        return (
                                            currentValue + newValue
                                        ).toString();
                                    }
                                )
                            };
                            combinedDatasets.push(updatedDataset);
                        } else {
                            // Si solo existe en accumulatedData, lo agregamos tal cual
                            combinedDatasets.push(accumulatedDataset);
                        }
                    });

                    // Procesar los datasets que están en newData pero no en accumulatedData
                    newData.datasets.forEach(newDataset => {
                        const existingDataset = accumulatedData.datasets.find(
                            dataset => dataset.id === newDataset.id
                        );

                        if (!existingDataset) {
                            // Si solo existe en newData, lo agregamos tal cual
                            combinedDatasets.push(newDataset);
                        }
                    });

                    // Asignamos el conjunto combinado de datasets
                    accumulatedData.datasets = combinedDatasets;
                }
            }
        });
        // Actualizar estados
        if (accumulatedData) {
            const newBreakpoints = labelList
                .map(item => {
                    if (
                        item >= dateFilterRange[0] &&
                        item <= dateFilterRange[1]
                    ) {
                        return item;
                    } else {
                        return '';
                    }
                })
                .filter(el => el !== '');
            const filteredLabels = accumulatedData.labels.filter(date =>
                newBreakpoints.includes(date)
            );
            const firstIndex: number = accumulatedData.labels.findIndex(
                date => date === filteredLabels[0]
            );
            const lastIndex: number = accumulatedData.labels.findIndex(
                date => date === filteredLabels[filteredLabels.length - 1]
            );
            const updatedDatasets = accumulatedData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(firstIndex, lastIndex + 1)
            }));
            const updatedParsedGarbagecontrolData = {
                labels: accumulatedData.labels.slice(firstIndex, lastIndex + 1), // Recortar las labels
                datasets: updatedDatasets
            };

            const updatedBags = bags.map(bag => {
                // Encontrar el dataset correspondiente al material actual por su id (material.name)
                const dataset = updatedParsedGarbagecontrolData.datasets.find(
                    objeto => parseFloat(objeto.capacity) === bag.capacity
                );
                if (dataset) {
                    const total = dataset.data
                        .map(Number)
                        .reduce(
                            (a, b) => (isNaN(b) ? a : Number(a) + Number(b)),
                            0
                        );
                    return {
                        ...bag,
                        show: total > 0
                    };
                }
                return {
                    ...bag,
                    show: false
                };
            });
            setBags(updatedBags);

            const selectedBags = updatedBags.filter(bag => bag.selected);
            const selectedBagIds = selectedBags.map(bag => bag.capacity);
            // Filtra dataApiledChart según los IDs de los materiales seleccionados
            if (selectedBagIds.length > 0) {
                const filteredDatasets =
                    updatedParsedGarbagecontrolData.datasets.filter(dataset =>
                        selectedBagIds.includes(dataset.id)
                    );
                const dataFormatedApiledFiltered = {
                    labels: updatedParsedGarbagecontrolData.labels,
                    datasets: filteredDatasets
                };
                setGarbageData(dataFormatedApiledFiltered);
                const removedDatasets =
                    updatedParsedGarbagecontrolData.datasets.filter(
                        dataset => !selectedBagIds.includes(dataset.id)
                    );
                setRemovedDatasets(removedDatasets);
            }
            setGarbageData(updatedParsedGarbagecontrolData);
        }
    };

    const unselectAll = () => {
        const updatedBags = bags.map(bag => {
            const removedDataset = garbageData.datasets.find(
                dataset => parseFloat(dataset.capacity) === bag.capacity
            );

            if (selectAll) {
                if (removedDataset) {
                    setRemovedDatasets(prevRemovedDatasets => [
                        ...prevRemovedDatasets,
                        removedDataset
                    ]);

                    setGarbageData(prevData => {
                        const datasets = prevData.datasets.filter(
                            dataset =>
                                parseFloat(dataset.capacity) !== bag.capacity
                        );
                        return {
                            ...prevData,
                            datasets
                        };
                    });
                }
                return { ...bag, selected: false };
            } else {
                const addedDataset = removedDatasets.find(
                    dataset => dataset.id === bag.name
                );

                if (addedDataset) {
                    setGarbageData(prevData => {
                        const datasets = [...prevData.datasets, addedDataset];
                        const costDatasets = datasets.filter(
                            dataset => dataset.id === 'Costo'
                        );
                        const otherDatasets = datasets
                            .filter(dataset => dataset.label !== 'Costo')
                            .sort((a, b) =>
                                a.capacity.localeCompare(b.capacity)
                            );
                        const sortedDatasets = [
                            ...costDatasets,
                            ...otherDatasets
                        ];
                        return {
                            ...prevData,
                            datasets: sortedDatasets
                        };
                    });
                    setRemovedDatasets(prevRemovedDatasets =>
                        prevRemovedDatasets.filter(
                            removedDataset =>
                                parseFloat(removedDataset.capacity) !==
                                bag.capacity
                        )
                    );
                    return { ...bag, selected: true };
                }
            }
            return bag;
        });
        setBags(updatedBags);
        setSelectAll(!selectAll);
    };

    const updateSelectedBags = (bag: any) => {
        if (!bag.selected) {
            const addedDataset = removedDatasets.find(
                dataset => parseFloat(dataset.capacity) === bag.capacity
            );
            if (addedDataset) {
                setGarbageData(prevData => {
                    const datasets = [...prevData.datasets, addedDataset];
                    const costDatasets = datasets.filter(
                        dataset => dataset.id === 'Costo'
                    );
                    const otherDatasets = datasets
                        .filter(dataset => dataset.id !== 'Costo')
                        .sort((a, b) => a.capacity.localeCompare(b.capacity));
                    const sortedDatasets = [...costDatasets, ...otherDatasets];
                    return {
                        ...prevData,
                        datasets: sortedDatasets
                    };
                });
                setRemovedDatasets(prevRemovedDatasets =>
                    prevRemovedDatasets.filter(
                        dataset => parseFloat(dataset.capacity) !== bag.capacity
                    )
                );
            }
        } else {
            const removedDataset = garbageData.datasets.find(
                dataset => parseFloat(dataset.capacity) === bag.capacity
            );
            if (removedDataset) {
                setRemovedDatasets(prevRemovedDatasets => [
                    ...prevRemovedDatasets,
                    removedDataset
                ]);

                setGarbageData(prevData => {
                    const datasets = prevData.datasets.filter(
                        dataset => parseFloat(dataset.capacity) !== bag.capacity
                    );
                    return {
                        ...prevData,
                        datasets
                    };
                });
            }
        }
        const updatedBags = bags.map(bagItem =>
            bagItem.capacity === bag.capacity
                ? { ...bagItem, selected: !bagItem.selected }
                : bagItem
        );
        setBags(updatedBags);
    };

    useEffect(() => {
        if (labels.length > 0) {
            const startDate = dayjs(
                `${labels[0].slice(0, 4)}-${labels[0].slice(4, 6)}-01`
            );
            const endDate = dayjs(
                `${labels[labels.length - 1].slice(0, 4)}-${labels[
                    labels.length - 1
                ].slice(4, 6)}-01`
            ).endOf('month');

            // Formatear las fechas como 'yyyymm'
            const formattedStartDate = startDate.format('YYYYMM');
            const formattedEndDate = endDate.format('YYYYMM');

            setDefaultStartDate(startDate);
            setDefaultEndDate(endDate);
            setDateFilterRange([formattedStartDate, formattedEndDate]);
        }
    }, [labels]);

    useEffect(() => {
        const garbagecontrolCard = localStorage.getItem(
            'evolution-card-historic'
        );
        const parsedGarbagecontrolCard = garbagecontrolCard
            ? JSON.parse(garbagecontrolCard)
            : [];
        const parsedGarbagecontrolData = parsedGarbagecontrolCard.data
            ? JSON.parse(parsedGarbagecontrolCard.data)
            : initValues;
        if (parsedGarbagecontrolData) {
            const labels = parsedGarbagecontrolData.labels;
            const arrayLabels = FormatLabels(labels);
            setLabelList(labels);
            setLabels(arrayLabels);
            const last12Labels = parsedGarbagecontrolData.labels.slice(-12);
            const updatedDatasets = parsedGarbagecontrolData.datasets.map(
                dataset => ({
                    ...dataset,
                    data: dataset.data.slice(-12)
                })
            );
            const updatedParsedEvolutionData = {
                ...parsedGarbagecontrolData,
                labels: last12Labels,
                datasets: updatedDatasets
            };
            setGarbageData(updatedParsedEvolutionData);
        }
    }, []);

    useEffect(() => {
        if (selected.length === 0 && selectedUnits.length === 0) {
            const garbagecontrolCard = localStorage.getItem(
                'garbagecontrol-card'
            );
            const parsedGarbagecontrolCard = garbagecontrolCard
                ? JSON.parse(garbagecontrolCard)
                : [];
            const parsedGarbagecontrolData = parsedGarbagecontrolCard.data
                ? JSON.parse(parsedGarbagecontrolCard.data)
                : initValues;

            const newBreakpoints = labelList
                .map(item => {
                    if (
                        item >= dateFilterRange[0] &&
                        item <= dateFilterRange[1]
                    ) {
                        return item;
                    } else {
                        return '';
                    }
                })
                .filter(el => el !== '');
            const filteredLabels = parsedGarbagecontrolData.labels.filter(
                date => newBreakpoints.includes(date)
            );
            const firstIndex: number =
                parsedGarbagecontrolData.labels.findIndex(
                    date => date === filteredLabels[0]
                );
            const lastIndex: number = parsedGarbagecontrolData.labels.findIndex(
                date => date === filteredLabels[filteredLabels.length - 1]
            );
            const updatedDatasets = parsedGarbagecontrolData.datasets.map(
                dataset => ({
                    ...dataset,
                    data: dataset.data.slice(firstIndex, lastIndex + 1)
                })
            );
            const updatedParsedGarbagecontrolData = {
                labels: parsedGarbagecontrolData.labels.slice(
                    firstIndex,
                    lastIndex + 1
                ), // Recortar las labels
                datasets: updatedDatasets
            };
            setGarbageData(updatedParsedGarbagecontrolData);
        } else {
            updateAllSelectedData();
        }
    }, [selected, selectedUnits, dateFilterRange]);

    useEffect(() => {
        formatLabelsShow(garbageData.labels);
    }, [garbageData]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='garbagecontrol' id='GCPage'>
                <Header title={{ name: title }} />
                <div
                    className='garbagecontrol__chart-container'
                    id='GCPage/GarbageControl'
                >
                    <div className='garbagecontrol__chart-container__filters'>
                        <div
                            className='garbagecontrol__chart-container__filters__bags'
                            id='GCPage/GarbageControl/InputSelection'
                        >
                            <div className='garbagecontrol__chart-container__filters__bags__title subtitle'>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#C4C3C5',
                                                colorBorder: '#C4C3C5',
                                                colorText: '#C4C3C5',
                                                colorPrimaryHover: '#C4C3C5'
                                            }
                                        }
                                    }}
                                >
                                    <Checkbox
                                        className='garbagecontrol__chart-container__filters__bags__title__checkbox'
                                        checked={selectAll}
                                        onChange={() => unselectAll()}
                                    />
                                    <label htmlFor='selectAll'>
                                        Seleccionar todos
                                    </label>
                                </ConfigProvider>
                            </div>
                            <div className='garbagecontrol__chart-container__filters__bags__filter'>
                                <button
                                    className='evolution__chart-container__filters__bags__filter__arrow-btn title'
                                    /* onClick={() => handleScroll('right')} */
                                ></button>
                                <div className='garbagecontrol__chart-container__filters__bags__filter__list'>
                                    {bags
                                        .filter(bag => bag.show)
                                        .map(bag => (
                                            <Tooltip
                                                title={
                                                    <span
                                                        key={bag.name}
                                                        className='main_menu__grid-container__tooltip'
                                                    >
                                                        {bag.name}
                                                    </span>
                                                }
                                                color='#fff'
                                                key={bag.name}
                                            >
                                                <button
                                                    key={bag.name}
                                                    onClick={() =>
                                                        updateSelectedBags(bag)
                                                    }
                                                >
                                                    <img
                                                        className={`garbagecontrol__chart-container__filters__bags__filter__list__logo${
                                                            !bag.selected
                                                                ? '__unselected'
                                                                : ''
                                                        }`}
                                                        src={`/svg-icons/supply-icons/${NormalizeName(
                                                            bag.name
                                                        )}.svg`}
                                                        alt={bag.name}
                                                        key={bag.name}
                                                        onError={e => {
                                                            const target =
                                                                e.target as HTMLImageElement;
                                                            target.src =
                                                                '/svg-icons/supply-icons/general.svg';
                                                        }}
                                                    ></img>
                                                </button>
                                            </Tooltip>
                                        ))}
                                </div>
                                <button
                                    className='evolution__chart-container__filters__bags__filter__arrow-btn title'
                                    /* onClick={() => handleScroll('right')} */
                                ></button>
                            </div>
                        </div>
                        <div
                            className='evolution__chart-container__filters__date-filter'
                            id='GCPage/GarbageControl/DateFilter'
                        >
                            <div className='evolution__chart-container__filters__date-filter__title subtitle'>
                                {dateRangeTitle}
                            </div>
                            <div className='evolution__chart-container__filters__date-filter__content'>
                                {defaultStartDate && (
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <RangePicker
                                            className='evolution__chart-container__filters__date-filter__content__date-picker'
                                            picker='month'
                                            format={'YYYY-MM'}
                                            onChange={ChangeDate}
                                            defaultValue={[
                                                defaultStartDate,
                                                defaultEndDate
                                            ]}
                                        />
                                    </ConfigProvider>
                                )}
                                <div className='evolution__chart-container__filters__date-filter__content__more'>
                                    <Popover
                                        className='evolution__popover'
                                        placement='bottom'
                                        trigger='click'
                                        content={
                                            <div className='evolution__popover__container'></div>
                                        }
                                    >
                                        <MoreOutlined
                                            style={{ color: '#1f1f1f' }}
                                        />
                                    </Popover>
                                </div>
                            </div>
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                    <div className='garbagecontrol__chart-container__chart'>
                        <ChartFormated
                            data={garbageData}
                            formattedLabels={formattedLabels}
                            /* labels={selectedLabels} */
                        />
                    </div>
                </div>
                <div className='garbagecontrol__costs' id='GCPage/CostControl'>
                    <div className='garbagecontrol__costs__title title'>
                        Control costos y entrega de bolsas
                    </div>
                    <div className='garbagecontrol__costs__subtitle subtitle'>
                        Periodo: mensual
                    </div>
                    {isMobile && <div></div>}
                    <GarbageCostsTable
                        onChange={GetParams}
                        showDetails={showDetails}
                        garbageData={garbageData}
                    />
                </div>
                <div className='garbagecontrol__buttons' id='GCPage/Buttons'>
                    <button
                        id='GCPage/Buttons/ViewDetails'
                        type='button'
                        className={`button button-body--download-garbage-control subtitle ${
                            !showCosts && 'disabled'
                        }`}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {showDetails ? 'Ocultar detalle' : 'Ver detalle'}
                    </button>
                    <button
                        id='GCPage/Buttons/DownloadCosts'
                        type='button'
                        className={`button button-body--download-garbage-control subtitle ${
                            !showCosts && 'disabled'
                        }`}
                        onClick={DownloadGarbageProratios}
                    >
                        Descargar costos
                    </button>
                </div>
            </div>
        </>
    );
};

export default GarbageControl;
