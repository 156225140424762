import './rightsection.scss';
import '../../main/main-private-style.scss';
import Menu from './menu/index';
import 'dayjs/locale/es';
import CalendarMini from './calendar-mini';

const RightSection = () => {
    return (
        <div className='right-container'>
            <Menu />
            <div className='right-container__calendar'>
                <CalendarMini />
            </div>
            <div className='right-container__messages'>
                {/* <p className='messages__title'> {remindersTitle}</p>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div>
                <div className='messages__content'>
                    <span className='messages__content__logo'>
                        <img src='/svg-icons/MessagesIcon.svg' />
                    </span>
                    <span className='messages__content__text'>
                        Mensaje mensaje mensaje
                    </span>
                    <hr className='messages__content__line'></hr>
                </div> */}
            </div>
        </div>
    );
};

export default RightSection;
