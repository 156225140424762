import './monthlyEvolution.scss';
import { useNavigate } from 'react-router';
//  <--COMPONENTS--> //
import ChartFormated from './components/chartFormated';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { lang } from '../../langs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface Props {
    data: any;
    formattedLabels: any;
}

const MonthlyEvolutionCard = ({ data, formattedLabels }: Props) => {
    const { LoadingData, LoaderElement } = useCallApi();

    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyEvolutionCard: {
                    evolutionButton,
                    monthlyIntegratedEvolution,
                    subtitle,
                    detailMessage
                }
            }
        }
    } = lang;
    const navigate = useNavigate();

    return (
        <div className='monthlyevolution-card' id='DatacPage/MainChart'>
            <div className='monthlyevolution-card__titulo-card title'>
                {monthlyIntegratedEvolution}
            </div>
            <div className='monthlyevolution-card__subtitulo-card subtitle'>
                {subtitle}
                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            {detailMessage}
                        </span>
                    }
                    color='#fff'
                >
                    <InfoCircleOutlined className='monthlyevolution-card__subtitulo-card__icon' />
                </Tooltip>
            </div>
            <div className='monthlyevolution-card__content'>
                <div className='monthlyevolution-card__content__chart'>
                    <ChartFormated
                        data={data}
                        formattedLabels={formattedLabels}
                    />
                </div>
                <button
                    className={`button button-body--evolution`}
                    onClick={() => navigate('/panel/evolution')}
                    id='DatacPage/MainChart/Details'
                >
                    <div className='button-body--evolution__content'>
                        <div className='button-body--evolution__content__text'>
                            {evolutionButton}
                        </div>
                        <img
                            className='button-body--evolution__content__img'
                            src='/svg-icons/Arrows.svg'
                        />
                    </div>
                </button>
            </div>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default MonthlyEvolutionCard;
