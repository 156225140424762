import React, { useEffect, useImperativeHandle } from 'react';
import { notification } from 'antd';
import {
    InfoCircleOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import './Toast.scss';

interface Provider {
    current: any;
}

interface Props {
    backColor?: string;
    key?: string;
    position?: string;
    dismissTime?: number;
    title?: string;
    text?: string;
    show?: boolean;
    onClose?: any;
}

export const ToastNew = React.forwardRef(function ToastNew(props: Props, ref) {
    const [api, contextHolder] = notification.useNotification();

    const messageProvider: Provider = React.useRef([]);
    const [open, setOpen] = React.useState(props.show);
    const [messageInfo, setMessageInfo] = React.useState<Props>({
        key: 'default',
        ...props
    });

    const getIconBasedOnColor = backColor => {
        switch (backColor) {
            case 'color-warning':
                return (
                    <ExclamationCircleOutlined style={{ color: '#ce3e50' }} />
                );
            case 'color-success':
                return <CheckCircleOutlined style={{ color: '#65c05a' }} />;
            case 'color-error':
                return <CloseCircleOutlined style={{ color: '#4d4d4d' }} />;
            case 'color-info':
                return <InfoCircleOutlined style={{ color: '#ce3e5' }} />;
            default:
                return null;
        }
    };

    const openNotification = (placement: NotificationPlacement) => {
        api.open({
            message: (
                <span className='toast' style={{ color: '#ce3e5' }}>
                    {' '}
                    {messageInfo.text}{' '}
                </span>
            ),
            placement,
            duration: props.dismissTime,
            icon: getIconBasedOnColor(messageInfo.backColor)
        });
    };

    useEffect(() => {
        openNotification('topRight');
    }, [open]);

    const processMessage = () => {
        if (messageProvider.current.length > 0) {
            setTimeout(() => {
                setMessageInfo(messageProvider.current.shift());
                setOpen(true);
            }, 50);
        }
    };

    const pushMesage = nextProps => {
        setOpen(false);
        if (nextProps.show) {
            messageProvider.current.push({
                ...props,
                ...nextProps,
                key: new Date().getTime()
            });
            processMessage();
        } else {
            messageProvider.current = [];
        }
    };

    useImperativeHandle(ref, () => ({
        changeToast(nextProps = {}) {
            pushMesage(nextProps);
        }
    }));

    return (
        <>
            {messageInfo !== undefined && open === true && (
                <div>{contextHolder}</div>
            )}
        </>
    );
});
