import { useEffect, useState } from 'react';
import MainLoader from './mainLoader';
import { GetStorage } from '../../../../config/utils/Storage';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';

export function UseConfigurePermissions() {
    const { width } = useWindowDimensions();
    const [isMainLoader, setIsMainLoader] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {}, [isMobile]);

    const RemoveElements = (difference: any) => {
        for (let itd = 0; itd < difference.length; itd++) {
            const itemDiference = difference[itd];
            const element = document.getElementById(`${itemDiference.code}`);
            if (element) {
                // element.remove();
                element.style.display = 'none';
                element.style.position = 'absolute';
                element.style.top = '-100%';
                element.style.left = '-100%';
                element.style.opacity = '0';
            }
        }
        setIsMainLoader(false);
    };

    const AssignPermissions = clientData => {
        const userData = GetStorage(
            process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE
        );
        const sectionsData: any = [];

        if (userData.appSectionData.length > 0) {
            const appSection = userData.appSectionData;
            for (let i1 = 0; i1 < appSection.length; i1++) {
                const section = appSection[i1];
                if (section) {
                    const object = { type: 'section', code: section.code };
                    sectionsData.push(object);
                    const components = section.appComponents;
                    for (let i2 = 0; i2 < components.length; i2++) {
                        const component = components[i2];
                        const itemComponent = {
                            type: 'component',
                            code: component.code,
                            tag: component.tag
                        };
                        sectionsData.push(itemComponent);
                        const functions = component.appFunctions;
                        for (let i3 = 0; i3 < functions.length; i3++) {
                            const func = functions[i3];
                            const itemnFunc = {
                                type: 'function',
                                code: func.code,
                                tag: func.tag
                            };
                            sectionsData.push(itemnFunc);
                        }
                    }
                }
            }
        }
        for (let l = 0; l < sectionsData.length; l++) {
            const item = sectionsData[l];
            const element = document.getElementById(`${item.code}`);
            if (element) {
                element.style.removeProperty('display');
                element.style.removeProperty('position');
                element.style.removeProperty('top');
                element.style.removeProperty('left');
                element.style.removeProperty('opacity');
            }
        }
        if (clientData.length > 0) {
            const rolesPermissions = clientData.map(item => {
                if (item.clientRole) {
                    return item.clientRole.clientRoleDetails;
                }
                return null;
            });
            const rolesLvl2 = rolesPermissions.filter(
                item => item !== undefined
            );
            const withoutNull = rolesLvl2.filter(item => item !== null);
            const finalArray: any = [];
            withoutNull.forEach(element => {
                element.forEach(element2 => {
                    finalArray.push(element2);
                });
            });

            // userData.clientData[0].clientRole.clientRoleDetails;
            const permissions = finalArray.map((item: any) => {
                let object;
                if (item.appSection) {
                    object = {
                        type: 'section',
                        code: item.appSection.code
                    };
                }
                if (item.appComponent) {
                    object = {
                        type: 'component',
                        code: item.appComponent.code,
                        tag: item.appComponent.tag
                    };
                }
                if (item.appFunction) {
                    object = {
                        type: 'function',
                        code: item.appFunction.code,
                        tag: item.appFunction.tag
                    };
                }
                return object;
            });
            const difference = sectionsData.filter(
                x => permissions.findIndex(per => per.code === x.code) === -1
            );

            setTimeout(() => {
                if (difference) {
                    RemoveElements(difference);
                }
            }, 10);
        } else {
            setIsMainLoader(false);
        }
    };

    const PermissionsLoader = () => {
        return isMainLoader ? <MainLoader /> : <></>;
    };

    return { AssignPermissions, PermissionsLoader };
}
