import {
    BellFilled,
    CheckCircleFilled,
    CommentOutlined
} from '@ant-design/icons';
import { IEventInfo } from '../calendar';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface IProps {
    event: IEventInfo;
    view: string;
}

const EventInfo = ({ event, view }: IProps) => {
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile].name;

    const showGestorName = selectedRole.includes('BZero');
    const showPropertyName =
        selectedRole.includes('BZero') || selectedRole.includes('Gestor');

    return (
        <>
            {view === 'week' ? (
                <div className='event subsubtitle'>
                    {/* Mostrar gestorName si se cumple showGestorName o si showGestorName y showPropertyName son falsos */}
                    {(showGestorName ||
                        (!showGestorName && !showPropertyName)) && (
                        <div className='event__title'>{event.gestorName}</div>
                    )}
                    {/* Mostrar propertyName solo si showPropertyName es verdadero */}
                    {showPropertyName && (
                        <div
                            className={
                                showGestorName
                                    ? 'event__property'
                                    : 'event__title'
                            }
                        >
                            {event.propertyName}
                        </div>
                    )}
                    <div className='event__property'>{event.hour}</div>
                </div>
            ) : view === 'day' ? (
                <div className='event-day subsubtitle'>
                    <div className='event-day__content'>
                        {(showGestorName ||
                            (!showGestorName && !showPropertyName)) && (
                            <div className='event-day__content__title'>
                                {event.gestorName}
                            </div>
                        )}
                        {showPropertyName && (
                            <div
                                className={
                                    showGestorName
                                        ? 'event-day__content__property'
                                        : 'event-day__content__title'
                                }
                            >
                                {event.propertyName}
                            </div>
                        )}
                        <div className='event__property'>{event.hour}</div>
                    </div>
                    <div className='event-day__buttons'>
                        <BellFilled className='event-day__buttons__bell' />
                        <CheckCircleFilled className='event-day__buttons__check' />
                        <CommentOutlined className='event-day__buttons__comment' />
                    </div>
                </div>
            ) : (
                <div className='event subsubtitle'>
                    {/* Si no se muestran gestorName ni propertyName, mostrar solo gestorName */}
                    {(showGestorName ||
                        (!showGestorName && !showPropertyName)) &&
                        event.gestorName}
                    {showGestorName && showPropertyName && ' - '}
                    {showPropertyName && event.propertyName}
                </div>
            )}
        </>
    );
};

export default EventInfo;
