import './create/create-calendar.scss';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Select } from 'antd';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../../config/hooks/useToast';
import {
    ApiCalendarDetailId,
    ApiGestorUserByGestorId,
    ApiTruckByGestorList
} from '../../../../config/service';
import { IEventInfo } from '../calendar';

interface IProps {
    handleClose: Dispatch<SetStateAction<void>>;
    onRefresh?: () => void;
    currentEvent: IEventInfo | null;
}

interface FormInterface {
    truck: string | null;
    gestorUser: string | null;
    note: string;
}

const AssignEventModal = ({ handleClose, onRefresh, currentEvent }: IProps) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [trucks, setTrucks] = useState<any[]>([]);
    const [gestorUsers, setGestorUsers] = useState<any[]>([]);
    const [formData, setFormData] = useState<FormInterface>({
        truck: null,
        gestorUser: null,
        note: ''
    });

    const onClose = () => {
        handleClose();
    };

    const onChange = (value, field) => {
        setShowAlert(false);
        setFormData(prevData => ({ ...prevData, [field]: value }));
    };

    const CallData = async () => {
        if (currentEvent?.gestor) {
            const { data: trucksData } = await GetData(
                ApiTruckByGestorList,
                HTTP_METHODS.POST,
                { list: [currentEvent.gestor] } // Pasar el gestor como clave-valor
            );
            const trucks = trucksData
                .map(truck => ({
                    value: truck.id,
                    label: truck.patent
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setTrucks(trucks);

            const { data: gestorUsersData } = await GetData(
                ApiGestorUserByGestorId(currentEvent.gestor),
                HTTP_METHODS.GET
            );
            const gestorUsers = gestorUsersData
                .map(gestorUser => ({
                    value: gestorUser.id,
                    label: `${gestorUser.user.name} ${gestorUser.user.lastName}`
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setGestorUsers(gestorUsers);
        }
    };

    const validateForm = () => {
        const { truck } = formData;
        if (!truck) {
            setShowAlert(true);
            return false;
        } else {
            setShowAlert(false);
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        try {
            /* await GetData(ApiCalendarCreate, HTTP_METHODS.POST, eventData); */
            setShowAlert(false);
            if (currentEvent) {
                const newData = {
                    ...currentEvent,
                    truck: formData.truck,
                    gestorUser: formData.gestorUser,
                    note: formData.note
                };
                const { status, message } = await GetData(
                    ApiCalendarDetailId(currentEvent._id),
                    HTTP_METHODS.PATCH,
                    newData
                );
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                    onClose();
                    if (onRefresh) {
                        onRefresh();
                    }
                    CallData();
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            }
            // Aquí puedes resetear el formulario si es necesario
        } catch (error) {
            console.error('Error al crear el evento', error);
        }
    };

    const resetForm = () => {
        setFormData({
            truck: null,
            gestorUser: null,
            note: ''
        });
        setShowAlert(false); // Si deseas esconder el mensaje de alerta
    };

    useEffect(() => {
        CallData();
    }, []);

    return (
        <div className='calendarRequest'>
            <div className={`calendarRequest__main`}>
                <ToastElement ref={toastManagerRef} />
                <div className='calendarRequest__main__menu subsubtitle'>
                    <CloseOutlined
                        className='calendarRequest__main__menu__close'
                        onClick={onClose}
                    />
                    <hr />
                </div>
                <div className='calendarRequest__main__header title'>
                    <div className='calendarRequest__main__header__title'>
                        <div className='calendarRequest__main__header__title__circle' />
                        <div className='calendarRequest__main__header__title__text'>
                            Asignar evento de retiro por gestor
                        </div>
                    </div>
                </div>
                <div className='calendarRequest__main__text subtitle'>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorPrimary: '#2994E6', // Cambiar color primario del Select
                                    colorBgContainerDisabled: '#f3f2f5',
                                    controlItemBgHover: '#f3f2f5',
                                    colorBgElevated: '#f3f2f5',
                                    optionSelectedColor: '#4d4d4d',
                                    optionFontSize: 12,
                                    fontSize: 12
                                },
                                Input: {
                                    colorPrimary: '#2994E6', // Cambiar color primario del Select
                                    colorBgContainerDisabled: '#f3f2f5',
                                    controlItemBgHover: '#f3f2f5',
                                    colorBgElevated: '#f3f2f5',
                                    activeBorderColor: '#2994E6',
                                    hoverBorderColor: '#2994E6',
                                    fontSize: 12
                                }
                            }
                        }}
                    >
                        <form
                            className='calendarRequest__main__form'
                            onSubmit={e => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <label className='calendarRequest__main__form__label'>
                                Camión:
                            </label>
                            <Select
                                className='calendarRequest__main__form__select'
                                showSearch
                                placeholder='Selecciona un camión'
                                optionFilterProp='label'
                                onChange={value => onChange(value, 'truck')}
                                options={trucks}
                                value={formData.truck}
                            />
                            <label className='calendarRequest__main__form__label'>
                                Usuario gestor (opcional):
                            </label>
                            <Select
                                showSearch
                                placeholder='Selecciona un usuario gestor'
                                optionFilterProp='label'
                                onChange={value =>
                                    onChange(value, 'gestorUser')
                                }
                                options={gestorUsers}
                                value={formData.gestorUser}
                            />
                            <label className='calendarRequest__main__form__label'>
                                Nota (opcional):
                            </label>
                            <Input
                                placeholder='Ingresa una nota'
                                count={{
                                    show: true,
                                    max: 100
                                }}
                                value={formData.note}
                                onChange={e => onChange(e.target.value, 'note')}
                            />
                            {showAlert && (
                                <div className='calendarRequest__main__form__alert'>
                                    Por favor, selecciona un camión.
                                </div>
                            )}
                            <div className='calendarRequest__main__form__btns'>
                                <button
                                    className={`button button-body--cancel subtitle`}
                                    onClick={e => {
                                        e.preventDefault(); // Prevenir el comportamiento por defecto (si es necesario)
                                        resetForm(); // Llamar a la función para resetear el formulario
                                    }}
                                >
                                    Cancelar
                                </button>
                                <button
                                    className={`button button-body--create subtitle`}
                                    /*  htmlType='submit' */
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </ConfigProvider>
                </div>
                {LoadingData && <LoaderElement />}
            </div>
        </div>
    );
};

export default AssignEventModal;
