import '../create-calendar.scss';
import { CloseOutlined } from '@ant-design/icons';
import CalendarRequestCreateComponent from '../components/create';

interface Props {
    onClose: () => void;
}

const CalendarRequest = ({ onClose }: Props) => {
    return (
        <>
            <div className='calendarRequest__main__menu subsubtitle'>
                <CloseOutlined
                    className='calendarRequest__main__menu__close'
                    onClick={onClose}
                />
                <hr />
            </div>
            <div className='calendarRequest__main__header title'>
                <div className='calendarRequest__main__header__title'>
                    <div className='calendarRequest__main__header__title__circle' />
                    <div className='calendarRequest__main__header__title__text'>
                        Crear solicitud de retiro por gestor
                    </div>
                </div>
            </div>
            <div className='calendarRequest__main__text subtitle'>
                <CalendarRequestCreateComponent />
            </div>
        </>
    );
};

export default CalendarRequest;
